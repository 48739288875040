@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Radio+Canada:wght@300;400;700&display=swap");


body {
  background-color: rgb(250, 250, 250);
  overflow-x: hidden;
}


.nav-link{
  color:#212529 !important;
}
.contact-text-align{
  text-align: left;
}

.contact-title{
    font-size: large;
}

.textboxleftmargin{
  margin-left: 8px;
}

.navbarbackgroundcolor{
  background-color:none !important;
}

.categoryheading{
  font-weight: 600; font-size: revert;
}

.categorybottomheading{
  margin-bottom:-4px;
}

.backgroundimagediv{
  background-image: url("../image/main.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  
}


.marginforherotext{
  margin-top: 115px;
}

.textpaddingfooter{
  padding:10px !important;
}
.pwhite{
  color: whitesmoke !important;
}

.purewhite{
  color: white !important;
}

.purewhitenav{
  color: white !important;
  font-weight: 500 !important;
  font-size: large !important;
}


